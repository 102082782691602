(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("sbInternalMsgBus"), require("sbJsExtends"), require("mobxReact"), require("ReactDOM"), require("sbRespBlockLib"), require("sbDataLayer"), require("gsap"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-league-list-presenter", ["React", "mobx", "sbInternalMsgBus", "sbJsExtends", "mobxReact", "ReactDOM", "sbRespBlockLib", "sbDataLayer", "gsap"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-league-list-presenter"] = factory(require("React"), require("mobx"), require("sbInternalMsgBus"), require("sbJsExtends"), require("mobxReact"), require("ReactDOM"), require("sbRespBlockLib"), require("sbDataLayer"), require("gsap"));
	else
		root["sb-responsive-league-list-presenter"] = factory(root["React"], root["mobx"], root["sbInternalMsgBus"], root["sbJsExtends"], root["mobxReact"], root["ReactDOM"], root["sbRespBlockLib"], root["sbDataLayer"], root["gsap"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__67__, __WEBPACK_EXTERNAL_MODULE__81__) {
return 